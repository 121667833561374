import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {

  constructor(
    private router: Router,
    private http: HttpClient
  ){
  }

  handleError(error: HttpErrorResponse) {
    
     if(error?.error?.message == 'Unauthenticated.'){
       this.router.navigateByUrl('/pages/authentication/login-v1');
       localStorage.removeItem('access_token');

     }

    // Return an observable with a user-facing error message.
  return throwError(() => error.error);
}
}
