export var GLOBAL = {
    // local server
    // url: 'http://localhost:8000',
  
    // preprode serveur
    // url: 'https://bilancarbonev2.fm6e.net',
  
    // prod server
    url: 'https://app.calculateurco2.org',
  };
  
  export var variable = 0 ;
  