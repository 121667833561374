import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from './../classes/user';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HandleErrorService } from './handleError.service';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  public user = new BehaviorSubject<User| null>(null);
  private url:string;

  constructor(
    private router:Router,
    private http: HttpClient,
    private _handleErrorsService: HandleErrorService
  ){
    this.url = GLOBAL.url;
  }

  setUser(value:User) {
    this.user.next(value);
  }

  defaultRedirect(){
    if(this.user){
      

      this.user.pipe(take(1)).subscribe(us =>{
        this.router.navigate(['/'+ us?.role])
      })
    }else{
      this.router.navigate(['/']);
    }
  }


  // ===================== GET METHOD ========================
  // ======================
  // =========

  // get auth Info
  getUser():Observable<any>{
    let token:any = localStorage.getItem('access_token');

    const headers    = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Authorization": `Bearer ${token}`
    });

    try{
      
      return this.http.get(`${this.url}/api/user/details`,{headers: headers})
      .pipe(
        map((res:any) => res?.data),
        
        catchError(this.handleError)
      );
    }catch(err){
      
       
    }
  }

  // get all [ collaborators , managers ]
  getAllUsers():Observable<any>{
    const token: any = localStorage.getItem('access_token');
    
    
    const headers = new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
    });
    return this.http.get(`${this.url}/api/users-listing`,{headers: headers})
    .pipe(
      catchError(this.handleError)
    );
  }


  // get all request access
  getRequestAccess():Observable<any>{
    const token: any = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    });

     return this.http.get(`${this.url}/api/users/request-access`,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
    );
  }


  // accept access demand
  acceptDemand(id:any):Observable<any>{
    const token: any = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    });

     return this.http.patch(`${this.url}/api/users/accept-demand/${id}`,null,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
    );
  }


  // refuse access demand
  refuseDemand(id:any):Observable<any>{
    const token: any = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    });

     return this.http.post(`${this.url}/api/users/refuse-demand/${id}`,null,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
    );
  }


  

  // ===================== DeLETE METHOD ========================
  // ======================
  // =========

  //! delete user
  deleteUser(id:any):Observable<any>{
    const token: any = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    });

     return this.http.delete(`${this.url}/api/users/${id}`,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
     );
  }

  hide(id:any):Observable<any>{
    const token: any = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    });

     return this.http.post(`${this.url}/api/hide-users/${id}`,null,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
     );
  }

 

 

  //! update password
  changePassword(data:any){
    let token:any = localStorage.getItem('access_token');
    const headers    = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Authorization": `Bearer ${token}`
    });

    return this.http.post(`${this.url}/api/users/change-password`,data,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
    );
  }

  // change status of user
  // @return disable or enable user
  changeUserStatus(id:any):Observable<any>{
    const token: any = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    });
     return this.http.post(`${this.url}/api/change-status/users/${id}`,null,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
    );
  }

  // change status of users collection
  // @return disable or enable users collection
  changeUsersStatus(ids:any):Observable<any>{

    let data = {ids:ids}
    const token: any = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    });

     return this.http.post(`${this.url}/api/users/change-status-collection`,data,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
    );
  }


  // change status of users collection
  // @return disable or enable users collection
  changeUserAvatar(data:any):Observable<any>{


    const token: any = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    });

     return this.http.post(`${this.url}/api/users/change-avatar`,data,{headers: headers}).pipe(
      catchError(this._handleErrorsService.handleError)
     );
  }


  private handleError(error: HttpErrorResponse) {
      
      window.location.href = '/pages/auth/login';
    return throwError(() => error.error);
  }

}
