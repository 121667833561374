import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subscription, throwError } from 'rxjs';
import { first, switchMap, map, take, catchError } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';
import { User } from '../classes/user';
import { pipe } from 'rxjs';

import { UserService } from './user.service';
import { GLOBAL } from './global';
// import { UserType } from '../enum/userType';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service/authentication.service';
// import { toastService } from 'src/app/core/services/reactiveService/toast.service';
// import { ErrorsMessageService } from './reactiveService/authErrors.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User | null = null;
  idToken: string | null | undefined;
  private url:string;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private router: Router,
    private _AuthenticationService: AuthenticationService
    // private toastService :toastService,
    // private _errorsMessageService: ErrorsMessageService

  ) {
    this.url = GLOBAL.url;

  }

  public getToken() {
    return localStorage.getItem('access_token');
  }

  /**
   * login method
   * @param data 
   * @returns 
   */
  login(data:any) {
        const headers = new HttpHeaders({
          "Content-Type": "application/json",
          "Accept": "application/json",
        });

        return new Promise((resolve, reject) => {
          this.http.post(`${this.url}/api/auth/login`,data,{headers: headers})
          .pipe(take(1), catchError(this.handleError))
          .subscribe((data:any) =>{
            this.userService.user.next(data?.user);
            localStorage.setItem('access_token',data.token);
            localStorage.setItem('currentUser', JSON.stringify(data.user));
            this._AuthenticationService.currentUserSubject.next(data.user)
            this.loginRedirection();
            resolve(data);
          },
          error => {
            reject(error);
          })
        })
  }


  /**
   * register form
   * @param data 
   * @returns 
   */
  register(data:any) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
    });

    return this.http.post(`${this.url}/api/auth/register`,data,{headers: headers}).pipe(
      catchError(this.handleError)
      );
  }

  

  /**
   * redirect to dashboard after login
   */
  async loginRedirection() {
    this.userService.getUser().pipe(take(1)).subscribe((user:User) => {
        if (!user) {
          this.router.navigate(["/pages/authentication/login-v1"]);
          return;
        }
        let redirectTo = "";
        redirectTo = '';

        this.router.navigate([redirectTo]);
    });
  }


  /**
   * logout method
   * @returns 
   */
  logout() {
    const token: any = localStorage.getItem('access_token');
    const headers    = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Authorization": `Bearer ${token}`
    });
    return this.http.post(`${this.url}/api/auth/logout`,null,{headers: headers});
  }

  // sendResetPassword(email: any){
  //   const headers    = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Accept": "application/json",
  //     "X-Requested-With": "XMLHttpRequest",
  //   });
  //   return this.http.post(`${this.url}/api/auth/forgot-password`,email,{headers: headers})
  // }

  // changePassword(data: any){
  //   const headers    = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Accept": "application/json",
  //     "X-Requested-With": "XMLHttpRequest",
  //   });
  //   return this.http.post(`${this.url}/api/auth/reset-password`,data,{headers: headers})
  // }


  private handleError(error: HttpErrorResponse) {

    // Return an observable with a user-facing error message.
    return throwError(() => error.error);
  }


}
