import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/core/classes/user';
import { UserType } from 'app/core/enum/userType';
import { AuthService } from 'app/core/services/auth.service';
// import { User } from 'app/core/classes/user';
import { UserService } from 'app/core/services/user.service';
import { log } from 'console';
import { catchError, map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router, 
    private _authenticationService: AuthenticationService,
    private _userService : UserService,
    private _authService : AuthService
  ) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this._userService.getUser().pipe(take(1),
    map((user: User) => {
      if (user && user.role == UserType.Admin) {
        return true;
      }
          this._authService.loginRedirection();
          this._router.navigate(['/pages/authentication/login-v1']);
          return false;
        }), 
    );    
  }
}
