// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  supportedLanguages: ['ar', 'fr', 'en'],
  apiUrl: 'http://localhost:4000',
  Upload: {
    apiUrl: 'https://app.calculateurco2.org/',
    uploads: 'https://app.calculateurco2.org/files/uploads/',

    uploadScript: 'files/upload.php',
    secret: 'ShVmYq3t6v9y$B&E)H@McQfTjWnZr4u7x!z%C*F-JaNdRgUkXp2s5v8y/B?D(G+K'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
