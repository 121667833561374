import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'dashboard',
    title: '',
    translate: '',
    type: 'section',
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: 'home',
    children: [
      {
        id: 'tableaux de bord',
        title: 'tableaux de bord',
        translate: 'MENU.DASHBOARD.COLLAPSIBLE',
        type: 'item',
        role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'home',
        url: 'dashboard'
      },
      {
        id: 'Simulations',
        title: 'Simulations',
        translate: 'MENU.PAGES.SIMULATIONS',
        type: 'item',
        icon: 'file-text',
        url: 'pages/simulations'
      },
      {
        id: 'Paiements',
        title: 'Paiements',
        translate: 'MENU.PAGES.PAYMENTS',
        type: 'item',
        icon: 'shopping-cart',
        url: 'pages/payments'
      },
      {
        id: 'Utilisateurs',
        title: 'Utilisateurs',
        translate: 'MENU.PAGES.USERS',
        type: 'item',
        icon: 'user',
        url: 'pages/users'
      },
    ]
  },
  // Apps & Pages
  {
    id: 'apps',
    type: 'section',
    title: 'Autres',
    translate: 'MENU.AUTRES.SECTION',
    icon: 'package',
    children: [
      {
        id: 'projets',
        title: 'Projets',
        translate: 'MENU.AUTRES.PROJETS',
        type: 'item',
        icon: 'layers',
        url: 'pages/projets'
      },
      {
        id: 'Translations',
        title: 'Translations',
        translate: 'MENU.AUTRES.TRANSLATIONS',
        type: 'item',
        icon: 'grid',
        url: 'pages/translations'
      },
      
    ]
  },
  // User Interface
];